<template>
  <div class="h-full flex flex-col">
    <div class="px-4 flex-1 flex flex-col overflow-y-scroll">
      <m-submissions-section class="mt-4 mb-8" />
      <m-miniApps-section class="mt-4 mb-8" />
      <m-a-graph-panel :student="student" class="mt-4" />
      <m-learning-summary-section
        v-if="student"
        :has-badges="hasBadges"
        :student="student"
        class="mt-4"
      />
      <div class="flex justify-center mb-4">
        <div
          class="py-2 px-8 mr-4 rounded-full font-bold cursor-pointer"
          :class="futureButtonCss"
          @click="showHistory = false"
        >
          予定
        </div>
        <div
          class="py-2 px-8 ml-4 rounded-full font-bold cursor-pointer"
          :class="historyButtonCss"
          @click="showHistory = true"
        >
          履歴
        </div>
      </div>
      <m-a-future-panel
        v-if="!showHistory"
        :reservations="reservations"
        :regularly-reservations="regularlyReservations"
        @delete="deleteReservation"
        @deleteRegular="deleteRegularlyReservation"
      />
      <m-a-history-panel
        v-else-if="learnings.length > 0 && showHistory"
        :learnings="learnings"
        :loadable="!learningFinished && learnings.length >= learningLimit"
        :loading="learningLoading"
        @load="getMoreLearning"
      />
      <div v-else-if="student" class="w-full text-center text-gray-500 text-sm">
        ※ 学習履歴がまだ存在しません
      </div>
    </div>
    <div
      class="w-full px-4 py-6 flex-none flex flex-col items-center border-b border-gray-300 bg-white shadow"
    >
      <p class="mb-4 text-lg font-bold">学習室情報</p>
      <div v-if="runningTimers.length === 0" class="flex flex-col items-center">
        <p
          class="w-full p-3 mb-4 rounded-md bg-primary-100 text-sm font-normal text-center"
        >
          目標を書いて学習をはじめよう！ ※
          学習室が空いていない時間帯は、タイムキーパーが起動するよ。
        </p>
        <router-link
          v-if="runningTimers.length === 0"
          to="/room/form"
          class="px-4 py-2 flex items-center rounded-md font-medium text-sm text-white bg-primary-500 transition-all duration-300 hover:bg-primary-600"
        >
          学習をはじめる
        </router-link>
      </div>
      <div v-else class="flex flex-col items-center">
        <p
          class="w-full p-3 mb-4 rounded-md bg-primary-100 text-sm font-normal text-center"
        >
          現在、タイムキーパーで学習時間を記録しています。
        </p>
        <router-link
          :to="`/learning/${runningTimers[0].ref.id}/timer`"
          class="px-4 py-2 rounded-md font-medium text-sm text-white bg-primary-500 transition-all duration-300 hover:bg-primary-600"
        >
          タイムキーパーを表示する
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MAFuturePanel from "@/components/analyze/MAFuturePanel.vue";
import MAHistoryPanel from "@/components/analyze/MAHistoryPanel.vue";
import MSubmissionsSection from "@/components/MSubmissionsSection.vue";
import MMiniAppsSection from "@/components/MMiniAppsSection.vue";
import MAGraphPanel from "@/components/analyze/MAGraphPanel.vue";
import MLearningSummarySection from "@/components/learning/MLearningSummarySection.vue";
import MLearningSummaryCard from "@/components/MLearningSummaryCard.vue";
import MBadgeSummaryCard from "@/components/MBadgeSummaryCard.vue";
import { Learning } from "@/entities/learning";
import store, { awaitStudentLoaded } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Student } from "@/entities/student";
import { fetchHasBadges } from "@/api/badge";
import { HasBadge } from "@/entities/badge";
import { Reservation } from "@/entities/reservation";
import { RegularlyReservation } from "@/entities/regularly_reservation";
import {
  deleteRegularlyReservation,
  deleteReservation,
  getRegularlyReservationsOf,
  getReservationsOf
} from "@/api/reservation";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MAFuturePanel,
    MAGraphPanel,
    MAHistoryPanel,
    MLearningSummarySection,
    MLearningSummaryCard,
    MBadgeSummaryCard,
    MSubmissionsSection,
    MMiniAppsSection
  }
})
export default class Home extends Vue {
  reservations: Reservation[] = [];
  regularlyReservations: RegularlyReservation[] = [];
  hasBadges: HasBadge[] = [];
  showHistory = true;

  get student(): Student | null {
    return store.state.student;
  }

  get learnings(): Learning[] {
    return [...store.state.learnings];
  }

  get learningLoading(): boolean {
    return store.state.learningLoading;
  }

  get learningFinished(): boolean {
    return store.state.learningFinished;
  }

  get learningLimit(): number {
    return store.state.learningLimit;
  }

  get runningTimers() {
    return store.state.learnings.filter(
      learning => learning.data.meta && learning.data.meta.timer
    );
  }

  get futureButtonCss() {
    if (!this.showHistory) {
      return {
        "bg-secondary-500": true,
        "text-white": true
      };
    } else {
      return {
        "text-secondary-500": true
      };
    }
  }

  get historyButtonCss() {
    if (this.showHistory) {
      return {
        "bg-secondary-500": true,
        "text-white": true
      };
    } else {
      return {
        "text-secondary-500": true
      };
    }
  }

  async deleteReservation(id: string) {
    const matchReservations = this.reservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.reservations = this.reservations.filter(item => item.ref.id !== id);
    try {
      await deleteReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`学習予約の削除に失敗しました\n\n${e}`);
      this.reservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete reservation"
      );
    }
  }

  async deleteRegularlyReservation(id: string) {
    const matchReservations = this.regularlyReservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.regularlyReservations = this.regularlyReservations.filter(
      item => item.ref.id !== id
    );
    try {
      await deleteRegularlyReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`学習予約の削除に失敗しました\n\n${e}`);
      this.regularlyReservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete regularlyReservation"
      );
    }
  }

  async getMoreLearning() {
    store.dispatch("getMoreLearning");
  }

  moveToBadge() {
    this.$router.push("/badge");
  }

  // toggleLang() {
  //   const lang = getLocale();
  //   setLocale(lang === "en" ? "ja" : "en");
  // }

  // translate = t;

  async created() {
    const student = await awaitStudentLoaded(store);
    try {
      const [
        hasBadges,
        reservations,
        regularlyReservations
      ] = await Promise.all([
        fetchHasBadges(student.ref),
        getReservationsOf(student.ref, Math.floor(Date.now() / 1000)),
        getRegularlyReservationsOf(student.ref),
        store.dispatch("getMiniApps")
      ]);
      this.hasBadges = hasBadges;
      this.regularlyReservations = regularlyReservations;
      this.reservations = reservations;
    } catch (e) {
      alert(`予期せぬエラーが発生しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to fetch datas of student"
      );
    }
  }
}
</script>
