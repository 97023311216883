
import MAFuturePanel from "@/components/analyze/MAFuturePanel.vue";
import MAHistoryPanel from "@/components/analyze/MAHistoryPanel.vue";
import MSubmissionsSection from "@/components/MSubmissionsSection.vue";
import MMiniAppsSection from "@/components/MMiniAppsSection.vue";
import MAGraphPanel from "@/components/analyze/MAGraphPanel.vue";
import MLearningSummarySection from "@/components/learning/MLearningSummarySection.vue";
import MLearningSummaryCard from "@/components/MLearningSummaryCard.vue";
import MBadgeSummaryCard from "@/components/MBadgeSummaryCard.vue";
import { Learning } from "@/entities/learning";
import store, { awaitStudentLoaded } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Student } from "@/entities/student";
import { fetchHasBadges } from "@/api/badge";
import { HasBadge } from "@/entities/badge";
import { Reservation } from "@/entities/reservation";
import { RegularlyReservation } from "@/entities/regularly_reservation";
import {
  deleteRegularlyReservation,
  deleteReservation,
  getRegularlyReservationsOf,
  getReservationsOf
} from "@/api/reservation";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MAFuturePanel,
    MAGraphPanel,
    MAHistoryPanel,
    MLearningSummarySection,
    MLearningSummaryCard,
    MBadgeSummaryCard,
    MSubmissionsSection,
    MMiniAppsSection
  }
})
export default class Home extends Vue {
  reservations: Reservation[] = [];
  regularlyReservations: RegularlyReservation[] = [];
  hasBadges: HasBadge[] = [];
  showHistory = true;

  get student(): Student | null {
    return store.state.student;
  }

  get learnings(): Learning[] {
    return [...store.state.learnings];
  }

  get learningLoading(): boolean {
    return store.state.learningLoading;
  }

  get learningFinished(): boolean {
    return store.state.learningFinished;
  }

  get learningLimit(): number {
    return store.state.learningLimit;
  }

  get runningTimers() {
    return store.state.learnings.filter(
      learning => learning.data.meta && learning.data.meta.timer
    );
  }

  get futureButtonCss() {
    if (!this.showHistory) {
      return {
        "bg-secondary-500": true,
        "text-white": true
      };
    } else {
      return {
        "text-secondary-500": true
      };
    }
  }

  get historyButtonCss() {
    if (this.showHistory) {
      return {
        "bg-secondary-500": true,
        "text-white": true
      };
    } else {
      return {
        "text-secondary-500": true
      };
    }
  }

  async deleteReservation(id: string) {
    const matchReservations = this.reservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.reservations = this.reservations.filter(item => item.ref.id !== id);
    try {
      await deleteReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`学習予約の削除に失敗しました\n\n${e}`);
      this.reservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete reservation"
      );
    }
  }

  async deleteRegularlyReservation(id: string) {
    const matchReservations = this.regularlyReservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.regularlyReservations = this.regularlyReservations.filter(
      item => item.ref.id !== id
    );
    try {
      await deleteRegularlyReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`学習予約の削除に失敗しました\n\n${e}`);
      this.regularlyReservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete regularlyReservation"
      );
    }
  }

  async getMoreLearning() {
    store.dispatch("getMoreLearning");
  }

  moveToBadge() {
    this.$router.push("/badge");
  }

  // toggleLang() {
  //   const lang = getLocale();
  //   setLocale(lang === "en" ? "ja" : "en");
  // }

  // translate = t;

  async created() {
    const student = await awaitStudentLoaded(store);
    try {
      const [
        hasBadges,
        reservations,
        regularlyReservations
      ] = await Promise.all([
        fetchHasBadges(student.ref),
        getReservationsOf(student.ref, Math.floor(Date.now() / 1000)),
        getRegularlyReservationsOf(student.ref),
        store.dispatch("getMiniApps")
      ]);
      this.hasBadges = hasBadges;
      this.regularlyReservations = regularlyReservations;
      this.reservations = reservations;
    } catch (e) {
      alert(`予期せぬエラーが発生しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to fetch datas of student"
      );
    }
  }
}
